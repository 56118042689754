<div class="mx-12 my-8 flex items-center">
    @if (section.path != null) {
        <a
            class="rounded-4 hover:text-text flex size-full items-center overflow-hidden py-8"
            [attr.aria-label]="
                expanded ? undefined : (section.label | translate)
            "
            [ngClass]="{
                'text-text-mid-contrast ': !selected,
                'hover:bg-surface-100': expanded,
            }"
            [routerLink]="[section.path]"
        >
            <ng-container *ngTemplateOutlet="buttonSection" />
        </a>
    } @else {
        <!-- button used when path is null or when section has an advanced sidebar-->
        @if (disabled) {
            <div class="text-text-mid-contrast flex w-max items-center">
                <ng-container *ngTemplateOutlet="sectionContent" />
            </div>
        } @else {
            <button
                class="rounded-4 hover:text-text flex size-full items-center justify-start overflow-hidden py-8"
                type="button"
                [attr.aria-label]="
                    expanded ? undefined : (section.label | translate)
                "
                [id]="id"
                [ngClass]="{
                    'text-text-mid-contrast ': !selected,
                    'hover:bg-surface-100': expanded,
                }"
                (click)="selectSection(section)"
            >
                <ng-container *ngTemplateOutlet="sectionContent" />
            </button>
        }

        <ng-template #sectionContent>
            <ng-container *ngTemplateOutlet="buttonSection" />
            <interacta-icon
                *ngIf="expanded && advancedSidebar"
                class="size-24 shrink-0 transition-opacity duration-300"
                [icon]="'chevron-small-right'"
                [ngClass]="{
                    'opacity-0': !expanded,
                    'opacity-100': expanded,
                    'text-text-primary': selected,
                }"
            />
        </ng-template>
    }
</div>

<ng-template #buttonSection>
    <interacta-icon
        *ngIf="section.icon"
        class="rounded-4 size-24 shrink-0 overflow-hidden transition-colors duration-300"
        [icon]="section.icon"
        [matTooltip]="section.label | translate"
        [matTooltipDisabled]="expanded"
        [ngClass]="{
            'text-text-primary': selected,
            'order-first': section.iconPosition === 'left',
            'mx-8': section.id !== 'admin-v2',
            'mx-10': section.id === 'admin-v2',
        }"
    />

    <span
        class="typo-medium-bold line-clamp-1 overflow-hidden text-left transition-all duration-300"
        [ngClass]="{
            'text-text': selected,
            'opacity-0': !expanded,
            'opacity-100': expanded,
        }"
    >
        {{ section.label | translate }}</span
    >

    <span
        *ngIf="newSection()"
        class="typo-xs-bold bg-surface-primary-low-contrast ml-8 mt-2 rounded-3xl px-8 py-2"
        >{{ 'SIDEBAR.NEW_SECTION_BADGE' | translate }}</span
    >
</ng-template>
